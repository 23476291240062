.rippleContainer {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

.rippleContainer .ripple {
  position: absolute;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background: white;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-animation: ripple var(--duration);
          animation: ripple var(--duration);
  z-index: 111;
  pointer-events: none;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    opacity: var(--opacity);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(2);
            transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    opacity: var(--opacity);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(2);
            transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}
