.rippleContainer {
    width: max-content;
    height: max-content;
    position: relative;
    overflow: hidden;
    isolation: isolate;

    .ripple {
        position: absolute;
        min-width: 100px;
        min-height: 100px;
        border-radius: 50%;
        background: rgb(255, 255, 255);
        transform: translate(-50%, -50%);
        opacity: 0;
        // ripple animation
        animation: ripple var(--duration);
        z-index: 111;
        pointer-events: none;
    }
}

// Ripple animation
@keyframes ripple {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: var(--opacity);
    }

    100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
    }
}