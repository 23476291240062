:root {
    --slider-main-color: #42A2E8;
    --slider-bg: #F5F5F5;
}

.slider {
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 10px 0;
    cursor: pointer;

    .track {
        width: 100%;
        height: 5px;
        background: var(--slider-bg);
        display: flex;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        border-radius: 999px;

        .slide {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: var(--slider-main-color);
        }

        .step {
            width: 0.0001px;
            height: 0.0001px;
            background: none;
        }
    }

    .thumb {
        width: 15px;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        background: white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.303);
        border: 3px solid var(--slider-main-color);
        // opacity: 0.1;
        // transition: left 0.15s ease;
    }
}